<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            borderColor="#e06919"
            title="Festas Religiosas"
            titleColor="#000"
            class="mb-5"
          >
            <template v-slot:conteudo>
              <p>
                Em Campinas, as principais festas religiosas são em louvor a
                Nossa Senhora Aparecida, a Festa de Santa Cruz e do Menino
                Jesus. Durante muitos anos, Santa Cruz foi cultuada como
                padroeira local. Considerando-se que Santa Cruz trata-se de
                devoção e culto, não se tratando de uma entidade canonizada, não
                poderia ser considerada padroeira de uma localidade. Diante
                disso, provavelmente por volta de 1960, Nossa Senhora Aparecida
                passou a ser a padroeira da comunidade, e Santa Cruz continuou a
                ser cultuada pelos moradores católicos de Campinas, mantendo a
                tradição e a devoção que está relacionada ao início do
                catolicismo no Brasil.
              </p>
              <p>
                A Festa do Menino Jesus faz parte do calendário local desde
                1990. Para esta celebração, é levantado o mastro no adro da
                Capela de Aparecida, da mesma forma que para cultuar Nossa
                Senhora Aparecida.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img width="100%" src="../../assets/sobre_cultura_campinas_01.png" />
          <p style="font-size: 0.5em; text-align: center;">
            Igreja de Nossa Senhora Aparecida, implantada em cota elevada, em
            posição de destaque na comunidade. Foto: Flora Passos, 2018.
          </p>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex flex-row-reverse justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                A Folia de Reis é organizada pelo Sr. Geraldo Gonçalves, e o
                grupo percorre as casas do subdistrito e de localidades próximas
                como Pedras, recolhendo “esmolas” para a realização dos
                folguedos em honra ao Menino Jesus. A Folia de Reis de Campinas
                foi registrada em 06 de janeiro de 2017 pelo Instituto Estadual
                do Patrimônio Histórico e Artístico de Minas Gerais (IEPHA) por
                sua importância cultural para o estado, fazendo parte do
                conjunto das Folias de Minas. Possui como devoção Nossa Senhora
                Aparecida e conta com 15 integrantes, que utilizam além da voz,
                instrumentos musicais como sanfona, caixa, pandeiro e
                xique-xique.
              </p>
              <p>
                Durante as festas tradicionais religiosas, ocorrem os
                novenários, e, no dia das festas, é celebrada missa. A
                socialização dos moradores acontece a partir dos festejos, nos
                quais são montadas barraquinhas, realizadas partidas de futebol
                e bingos. Após o rompimento da barragem, os rituais festivos
                foram mantidos, apesar da diminuição de público diante do
                deslocamento forçado de pessoas atingidas em comunidades
                próximas. No mês de janeiro, é celebrada a reza de ano novo e a
                reza de Santos Reis. Todas as festividades estão presentes no
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_religiosidade_calendario' }"
                  >calendário litúrgico</router-link
                >
                do território atingido.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            title="Capela de Nossa Senhora Aparecida:"
            titleColor="#000"
            borderColor="#e06919"
            class="mb-5"
          >
            <template v-slot:conteudo>
              <p>
                A capela e o cruzeiro existente no adro foram implantados em
                posição de destaque em terreno de nível elevado em relação à
                rua, acessados por uma escadaria com piso de concreto. O templo
                foi construído na década de 1990 com características
                arquitetônicas contemporâneas, volumetria simplificada, sem
                ornamentos externos e internos. A torre destaca-se na fachada
                frontal, onde se encontra a porta de acesso principal com vão de
                verga reta. A fachada simétrica possui dois vitrôs com verga
                arqueada e fechamento com caixilho metálico e vidros coloridos.
                Antecedendo o acesso principal, existe uma laje com acabamento
                de argamassa texturizada ao modo da trama das esteiras de
                taquara. O mesmo acabamento foi aplicado na parte de baixo da
                escada interna que conduz ao coro.
              </p>
              <p style="text-align: center">
                <strong>Autora: Ana Paula Alves Ferreira <sup>2</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section class="table__section">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-center align-center mb-5 mt-3">
            <img
              width="2%"
              src="../../assets/sobre_cultura_campinas_icon_01.png"
            />
            <h6 class="ml-3">Calendário Litúrgico</h6>
          </div>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    <p
                      style="text-align: center; margin-bottom: 0; font-weight: bold;"
                    >
                      Comunidade
                    </p>
                  </th>
                  <th class="text-center">
                    <p
                      style="text-align: center; margin-bottom: 0; font-weight: bold;"
                    >
                      Manifestação Cultural
                    </p>
                  </th>
                  <th class="text-center">
                    <p
                      style="text-align: center; margin-bottom: 0; font-weight: bold;"
                    >
                      Local
                    </p>
                  </th>
                  <th class="text-center">
                    <p
                      style="text-align: center; margin-bottom: 0; font-weight: bold;"
                    >
                      Época do ano
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in calendarioLiturgico" :key="item.name">
                  <td>
                    <p style="text-align: center; margin-bottom: 0;">
                      {{ item.name }}
                    </p>
                  </td>
                  <td>
                    <p style="text-align: center; margin-bottom: 0;">
                      {{ item.manifestacaoCultural }}
                    </p>
                  </td>
                  <td>
                    <p style="text-align: center; margin-bottom: 0;">
                      {{ item.local }}
                    </p>
                  </td>
                  <td>
                    <p style="text-align: center; margin-bottom: 0;">
                      {{ item.data }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </section>
    <section class="table__section">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-center align-center mb-5 mt-3">
            <img
              width="2%"
              src="../../assets/sobre_cultura_campinas_icon_02.png"
            />
            <h6 class="ml-3">Festas e Manifestações Populares</h6>
          </div>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    <p
                      style="text-align: center; margin-bottom: 0; font-weight: bold;"
                    >
                      Manifestação Cultural
                    </p>
                  </th>
                  <th class="text-center">
                    <p
                      style="text-align: center; margin-bottom: 0; font-weight: bold;"
                    >
                      Local
                    </p>
                  </th>
                  <th class="text-center">
                    <p
                      style="text-align: center; margin-bottom: 0; font-weight: bold;"
                    >
                      Entrevistados
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in festasPopulares" :key="item.name">
                  <td>
                    <p style="text-align: center; margin-bottom: 0;">
                      {{ item.manifestacaoCultural }}
                    </p>
                  </td>
                  <td>
                    <p style="text-align: center; margin-bottom: 0;">
                      {{ item.local }}
                    </p>
                  </td>
                  <td>
                    <p style="text-align: center; margin-bottom: 0;">
                      {{ item.entrevistados }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </section>
    <section class="table__section">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-center align-center mb-5 mt-3">
            <img
              width="2%"
              src="../../assets/sobre_cultura_campinas_icon_03.png"
            />
            <h6 class="ml-3">Locais de lazer e Recreação junto à natureza</h6>
          </div>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    <p
                      style="text-align: center; margin-bottom: 0; font-weight: bold;"
                    >
                      Recreação
                    </p>
                  </th>
                  <th class="text-center">
                    <p
                      style="text-align: center; margin-bottom: 0; font-weight: bold;"
                    >
                      Local
                    </p>
                  </th>
                  <th class="text-center">
                    <p
                      style="text-align: center; margin-bottom: 0; font-weight: bold;"
                    >
                      Entrevistados
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in recreacaoLazer" :key="item.name">
                  <td>
                    <p style="text-align: center; margin-bottom: 0;">
                      {{ item.recreacao }}
                    </p>
                  </td>
                  <td>
                    <p style="text-align: center; margin-bottom: 0;">
                      {{ item.local }}
                    </p>
                  </td>
                  <td>
                    <p style="text-align: center; margin-bottom: 0;">
                      {{ item.entrevistados }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </section>
    <section class="table__section">
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-center align-center mb-5 mt-3">
            <img
              width="2%"
              src="../../assets/sobre_cultura_campinas_icon_04.png"
            />
            <h6 class="ml-3">Saberes e Modo de fazer</h6>
          </div>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    <p
                      style="text-align: center; margin-bottom: 0; font-weight: bold;"
                    >
                      Técnica
                    </p>
                  </th>
                  <th class="text-center">
                    <p
                      style="text-align: center; margin-bottom: 0; font-weight: bold;"
                    >
                      Local
                    </p>
                  </th>
                  <th class="text-center">
                    <p
                      style="text-align: center; margin-bottom: 0; font-weight: bold;"
                    >
                      Entrevistados
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in saberesModos" :key="item.name">
                  <td>
                    <p style="text-align: center; margin-bottom: 0;">
                      {{ item.tecnica }}
                    </p>
                  </td>
                  <td>
                    <p style="text-align: center; margin-bottom: 0;">
                      {{ item.local }}
                    </p>
                  </td>
                  <td>
                    <p style="text-align: center; margin-bottom: 0;">
                      {{ item.entrevistados }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </section>
    <section class="table__section">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>2</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {
      calendarioLiturgico: [
        {
          name: "------",
          manifestacaoCultural: "Festa de Santa Cruz",
          local: "Capela de N. Sra. Aparecida",
          data: "Maio",
        },
        {
          name: "------",
          manifestacaoCultural: "Festa do Menino Jesus",
          local: "Capela de N. Sra. Aparecida",
          data: "Junho",
        },
        {
          name: "------",
          manifestacaoCultural: "Festa de N. Sra. Aparecida",
          local: "Capela de N. Sra. Aparecida",
          data: "Outubro",
        },
        {
          name: "------",
          manifestacaoCultural: "Folia de Reis",
          local: "------",
          data: "de 28 de Dezembro a 06 de Janeiro",
        },
      ],
      festasPopulares: [
        {
          manifestacaoCultural: "",
          local: "",
          entrevistados: "",
        },
      ],
      recreacaoLazer: [
        {
          recreacao: "Banho",
          local: "Cachoeira do Ó",
          entrevistados: "",
        },
      ],
      saberesModos: [
        {
          tecnica: "Uso de jiqui para pesca",
          local: "Rio Gualaxo do Norte",
          entrevistados: "",
        },
        {
          tecnica: "Cestos de taquara",
          local: "",
          entrevistados: "",
        },
      ],
    };
  },
};
</script>

<style scoped>
h6 {
  font-size: 0.8em;
}
.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.table__section {
  margin-top: 3em;
  margin-bottom: 3em;
}

#app
  > div
  > main
  > div
  > div
  > div.container
  > section
  > div
  > div
  > div.v-data-table.theme--light
  > div
  > table
  > thead
  > tr
  > th {
  color: black;
  font-size: 0.6em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  margin-top: 2em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
